/* @TODO add a note about home insurance. */

import { useIntl } from 'gatsby-plugin-intl';
import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { RentApplicationForm } from '../../../interfaces/common';
import TextArea from '../../common/forms/TextArea';

interface Props {
  register: UseFormMethods<RentApplicationForm>['register'];
  isLoading: boolean;
}

const MoreInfoStep = ({ register, isLoading }: Props): ReactElement => {
  const intl = useIntl();

  return (
    <TextArea
      inputName="moreInfo"
      inputRef={register}
      label={intl.formatMessage({ id: 'form.moreInfo.label' })}
      isOptional={true}
      isDisabled={isLoading}
    />
  );
};

export default MoreInfoStep;
