import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { breakpoints, fontWeight, spacing } from '../../styles';
import successSvg from '../../images/icons/success.svg';
import { FormattedMessage } from 'gatsby-plugin-intl';

interface Props {
  name: string;
}

const StyledImg = styled.img`
  width: 100%;

  padding: ${spacing['8']} 0;

  @media only screen and (min-width: ${breakpoints.sm}) {
    width: 70%;
  }
`;

const StyledContainer = styled.div`
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessMessage = ({ name }: Props): ReactElement => {
  return (
    <StyledContainer>
      <StyledImg src={successSvg} />
      <h1 style={{ textAlign: 'center', fontWeight: fontWeight.regular }}>
        <FormattedMessage id="form.sent" values={{ name }} />
      </h1>
    </StyledContainer>
  );
};

export default SuccessMessage;
