import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { ReactElement, useState } from 'react';
import { Row } from 'react-flexbox-grid';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { joiResolver } from '@hookform/resolvers/joi';

import {
  Apartment,
  RentApplicationForm as RentApplicationFormType,
} from '../../interfaces/common';
import { getRentApplicationSchema } from '../../services/forms';
import Button from '../common/Button';
import Divider from '../common/Divider';
import { spacing } from '../../styles';
import SuccessMessage from './SuccessMessage';
import BasicInfoStep from './steps/BasicInfoStep';
import BackgroundInfoStep from './steps/BackgroundInfoStep';
import MoreBackgroundInfoStep from './steps/MoreBackgroundInfoStep';
import MoreInfoStep from './steps/MoreInfoStep';
import FailedMessage from './FailedMessage';

type Props = {
  apartment: Apartment;
};

const StyledForm = styled.form`
  padding: ${spacing['7']} ${spacing['2']};
`;

type State = {
  isLoading: boolean;
  formData: RentApplicationFormType | null;
  formFailed: boolean;
  formSuccess: boolean;
};

const initialState: State = {
  isLoading: false,
  formData: null,
  formFailed: false,
  formSuccess: false,
};

const getApiData = (
  formData: RentApplicationFormType & {
    apartmentId: string;
  },
) => ({
  apartment_id: formData.apartmentId,
  first_name: formData.firstName,
  last_name: formData.lastName,
  email: formData.email,
  phone_number: formData.phoneNumber,
  reason_for_moving: formData.reasonForMoving,
  tenant_background: formData.tenantBackground,
  move_in_date: formData.moveInDate,
  credit: formData.credit,
  pets: formData.pets,
  smoke: formData.smoke,
  rent_full_period: formData.rentFullPeriod,
  gross_income: formData.grossIncome,
  guarantor: formData.guarantor,
  reference: formData.reference,
  credit_more_info: formData.creditMoreInfo,
  pets_more_info: formData.petsMoreInfo,
  rent_full_period_more_info: formData.rentFullPeriodMoreInfo,
  // more_rent: formData.moreRent,
  // more_rent_amount: formData.moreRentAmount,
  more_info: formData.moreInfo,
});

const rentApplication = getRentApplicationSchema();

const RentApplicationForm = ({ apartment }: Props): ReactElement => {
  const [state, setState] = useState<State>(initialState);

  const {
    register,
    handleSubmit,
    errors,
    formState,
    watch,
  } = useForm<RentApplicationFormType>({
    resolver: joiResolver(rentApplication),
  });

  const onSubmit = async (formData: RentApplicationFormType) => {
    setState({ ...state, formData, isLoading: true });
    let result: Response;

    const notifyAPI = process.env.GATSBY_NOTIFY_API;

    if (!notifyAPI) {
      setState({ ...state, isLoading: false, formFailed: true });
      return;
    }

    try {
      result = await fetch(notifyAPI, {
        method: 'POST',
        body: JSON.stringify(
          getApiData({ ...formData, apartmentId: apartment.id }),
        ),
        headers: { 'Content-Type': 'application/json' },
      });

      if (result.ok) {
        setState({ ...state, isLoading: false, formData, formSuccess: true });
      } else {
        setState({ ...state, isLoading: false, formFailed: true });
      }
    } catch (error) {
      setState({ ...state, isLoading: false, formFailed: true });
    }
  };

  if (state.formSuccess) {
    return (
      <SuccessMessage name={state.formData ? state.formData.firstName : ''} />
    );
  }

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <BasicInfoStep
        isLoading={state.isLoading}
        isSubmitted={formState.isSubmitted}
        errors={errors}
        register={register}
      />
      <Divider />
      <BackgroundInfoStep
        errors={errors}
        isSubmitted={formState.isSubmitted}
        isLoading={state.isLoading}
        register={register}
        apartment={apartment}
      />

      <Divider />
      <MoreBackgroundInfoStep
        errors={errors}
        isSubmitted={formState.isSubmitted}
        isLoading={state.isLoading}
        register={register}
        apartment={apartment}
        watch={watch}
      />
      <Divider />

      <MoreInfoStep register={register} isLoading={state.isLoading} />

      <Row style={{ margin: 0 }} center="xs">
        <Button isLoading={state.isLoading}>
          <FormattedMessage id="form.submit" />
        </Button>
        <FailedMessage formFailed={state.formFailed} />
      </Row>
    </StyledForm>
  );
};

export default RentApplicationForm;
