import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { ReactElement } from 'react';
import ErrorContainer from '../common/forms/ErrorContainer';

interface Props {
  formFailed: boolean;
}

const FailedMessage = ({ formFailed }: Props): ReactElement => {
  return (
    <>
      {formFailed && (
        <ErrorContainer>
          <FormattedMessage
            id="form.failed"
            values={{
              email: process.env.GATSBY_CUSTOMER_SERVICE_EMAIL,
              br: <br />,
            }}
          />
        </ErrorContainer>
      )}
    </>
  );
};

export default FailedMessage;
