import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { ReactElement, ReactNodeArray } from 'react';
import { UseFormMethods } from 'react-hook-form';
import styled from 'styled-components';

import {
  borderRadius,
  fontColor,
  fontWeight,
  grey,
  purple,
  spacing,
  white,
} from '../../../styles';
import ErrorContainer from './ErrorContainer';
import InputContainer from './InputContainer';
import Label from './Label';

interface Props {
  label: string | ReactNodeArray;
  inputName: string;
  inputRef: UseFormMethods['register'];
  hasError?: boolean;
  isOptional?: boolean;
  isDisabled?: boolean;
  errorMsg?: string;
  type?:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';
}

const StyledInput = styled.input<Pick<Props, 'isDisabled'>>`
  border: 2px solid ${grey['2']};
  padding: ${spacing['3']};
  border-radius: ${borderRadius.lg};
  color: ${fontColor.primary};
  font-weight: ${fontWeight['medium']};
  width: calc(100% - ${spacing['3']} * 2);
  background-color: ${white};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

  &:focus,
  &:active {
    border-color: ${purple['3']};
    outline: 0;
  }
`;

const Input = ({
  inputName,
  inputRef,
  label,
  isOptional,
  hasError,
  errorMsg,
  type,
  isDisabled,
}: Props): ReactElement => {
  return (
    <InputContainer>
      <Label htmlFor={inputName}>{label}</Label>
      <StyledInput
        disabled={isDisabled}
        isDisabled={isDisabled}
        type={type || 'text'}
        name={inputName}
        ref={inputRef({ required: !Boolean(isOptional) })}
      />
      {hasError && !isOptional && (
        <ErrorContainer>
          {errorMsg || <FormattedMessage id="form.common.error.requiredInfo" />}
        </ErrorContainer>
      )}
    </InputContainer>
  );
};

export default Input;
