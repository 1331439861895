import React, { ReactElement, ReactNodeArray } from 'react';
import { UseFormMethods } from 'react-hook-form';
import styled from 'styled-components';
import { RadioSwitchValue } from '../../../interfaces/common';

import { borderRadius, grey, purple, spacing, white } from '../../../styles';

const StyledInput = styled.input`
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
`;

const StyledLabel = styled.label<Pick<Props, 'side' | 'isDisabled'>>`
  position: relative;
  cursor: pointer;
  display: inline-block;
  border: 2px solid ${grey['2']};
  padding: ${spacing['2']} ${spacing['5']};
  background-color: ${white};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

  border-bottom-right-radius: ${({ side }) =>
    side === 'right' ? borderRadius.lg : 0};

  border-top-right-radius: ${({ side }) =>
    side === 'right' ? borderRadius.lg : 0};

  border-top-left-radius: ${({ side }) =>
    side === 'left' ? borderRadius.lg : 0};

  border-bottom-left-radius: ${({ side }) =>
    side === 'left' ? borderRadius.lg : 0};

  border-right-width: ${({ side }) => (side === 'left' ? '1px' : '2px')};
  border-left-width: ${({ side }) => (side === 'left' ? '2px' : '1px')};

  ${StyledInput}:checked + &,
  ${StyledInput}:focus + &,
  &:hover {
    background-color: ${purple['1']};
    border-color: ${purple['3']};
    color: ${purple['3']};
  }
`;

interface Props {
  label: string | ReactNodeArray;
  inputName: string;
  inputRef: UseFormMethods['register'];
  isOptional?: boolean;
  id: string;
  value: RadioSwitchValue;
  side: 'right' | 'left';
  isDisabled?: boolean;
}

const RadioSwitch = ({
  label,
  inputName,
  inputRef,
  isOptional,
  id,
  value,
  side,
  isDisabled,
}: Props): ReactElement => {
  return (
    <>
      <StyledInput
        type="radio"
        id={id}
        name={inputName}
        ref={inputRef({ required: !Boolean(isOptional) })}
        value={value}
        disabled={isDisabled}
      />
      <StyledLabel htmlFor={id} side={side} isDisabled={isDisabled}>
        {label}
      </StyledLabel>
    </>
  );
};

export default RadioSwitch;
