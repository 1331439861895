import styled from 'styled-components';
import { fontWeight, spacing, fontSize, fontColor } from '../../../styles';

export default styled.label`
  display: block;
  padding: ${spacing['1']} 0;
  font-weight: ${fontWeight['regular']};
  font-size: ${fontSize['large']};
  color: ${fontColor.primary};
`;
