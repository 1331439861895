import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React, { ReactElement } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { Apartment, RentApplicationForm } from '../../../interfaces/common';
import { formatDate } from '../../../services/dates';
import Input from '../../common/forms/Input';
import InputContainer from '../../common/forms/InputContainer';
import TextArea from '../../common/forms/TextArea';

interface Props {
  errors: UseFormMethods<RentApplicationForm>['errors'];
  register: UseFormMethods<RentApplicationForm>['register'];
  isSubmitted: boolean;
  isLoading: boolean;
  apartment: Apartment;
}

const BackgroundInfoStep = ({
  errors,
  isSubmitted,
  register,
  isLoading,
  apartment,
}: Props): ReactElement => {
  const intl = useIntl();

  return (
    <>
      <InputContainer>
        <h3>
          <FormattedMessage id="form.aboutYouSection.title" />
        </h3>
        <p>
          <FormattedMessage id="form.aboutYouSection.desc" />
        </p>
      </InputContainer>

      <TextArea
        inputName="tenantBackground"
        inputRef={register}
        label={intl.formatMessage({ id: 'form.tenantBackground.label' })}
        hasError={errors.tenantBackground && isSubmitted}
        isDisabled={isLoading}
      />

      <TextArea
        inputName="reasonForMoving"
        inputRef={register}
        label={intl.formatMessage({ id: 'form.reasonForMoving.label' })}
        hasError={errors.reasonForMoving && isSubmitted}
        isDisabled={isLoading}
      />
      <Input
        inputName="grossIncome"
        inputRef={register}
        label={intl.formatMessage({ id: 'form.grossIncome.label' })}
        isDisabled={isLoading}
        hasError={errors.grossIncome && isSubmitted}
      />
      <Input
        inputName="guarantor"
        inputRef={register}
        label={intl.formatMessage({ id: 'form.guarantor.label' })}
        isDisabled={isLoading}
        isOptional={true}
      />
      <Input
        inputName="reference"
        inputRef={register}
        label={intl.formatMessage({ id: 'form.reference.label' })}
        isDisabled={isLoading}
        isOptional={true}
      />

      <Input
        inputName="moveInDate"
        inputRef={register}
        label={intl.formatMessage(
          { id: 'form.moveInDate.label' },
          { date: formatDate(apartment.availableFrom) },
        )}
        hasError={errors.moveInDate && isSubmitted}
        isDisabled={isLoading}
      />
    </>
  );
};

export default BackgroundInfoStep;
