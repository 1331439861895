import { useIntl } from 'gatsby-plugin-intl';
import React, { ReactElement } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { UseFormMethods } from 'react-hook-form';
import { RentApplicationForm } from '../../../interfaces/common';

import Input from '../../common/forms/Input';

interface Props {
  errors: UseFormMethods<RentApplicationForm>['errors'];
  register: UseFormMethods<RentApplicationForm>['register'];
  isSubmitted: boolean;
  isLoading: boolean;
}

const BasicInfoStep = ({
  errors,
  isSubmitted,
  register,
  isLoading,
}: Props): ReactElement => {
  const intl = useIntl();

  return (
    <Row>
      <Col xs={12} md={6}>
        <Input
          inputName="firstName"
          inputRef={register}
          label={intl.formatMessage({ id: 'form.firstName.label' })}
          hasError={errors.firstName && isSubmitted}
          isDisabled={isLoading}
        />
        <Input
          inputName="phoneNumber"
          inputRef={register}
          label={intl.formatMessage({ id: 'form.phoneNumber.label' })}
          hasError={errors.phoneNumber && isSubmitted}
          isDisabled={isLoading}
        />
      </Col>
      <Col xs={12} md={6}>
        <Input
          inputName="lastName"
          inputRef={register}
          label={intl.formatMessage({ id: 'form.lastName.label' })}
          hasError={errors.lastName && isSubmitted}
          isDisabled={isLoading}
        />
        <Input
          inputName="email"
          inputRef={register}
          label={intl.formatMessage({ id: 'form.email.label' })}
          hasError={errors.email && isSubmitted}
          isDisabled={isLoading}
        />
      </Col>
    </Row>
  );
};

export default BasicInfoStep;
