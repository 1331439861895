import Joi, { ObjectSchema } from 'joi';
import { RentApplicationForm } from '../interfaces/common.d';

const shortString = Joi.string().max(50);
const longString = Joi.string().max(3000);
const switchBoolean = Joi.string().min(1).max(10);

export const getRentApplicationSchema = (): ObjectSchema<RentApplicationForm> =>
  Joi.object<RentApplicationForm>({
    firstName: shortString.required(),
    lastName: shortString.required(),
    phoneNumber: shortString.required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    reasonForMoving: longString.required(),
    guarantor: shortString.optional().allow(''),
    reference: shortString.optional().allow(''),
    tenantBackground: longString.required(),
    moveInDate: shortString.required(),
    pets: switchBoolean.required(),
    petsMoreInfo: longString,
    smoke: switchBoolean.required(),
    credit: switchBoolean.required(),
    creditMoreInfo: longString,
    rentFullPeriod: switchBoolean.required(),
    rentFullPeriodMoreInfo: longString,
    // moreRent: switchBoolean.required(),
    // moreRentAmount: shortString,
    moreInfo: longString.optional().allow(''),
    grossIncome: shortString.required(),
  });
