import React, { ReactElement } from 'react';
import { PageProps } from 'gatsby';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { Row } from 'react-flexbox-grid';

import { Apartment } from '../interfaces/common';
import Layout from '../layouts/layout';
import ApartmentCard from '../components/landing-page/ApartmentCard';
import SEO from '../components/common/SEO';
import RentApplicationForm from '../components/rent-application/RentApplicationForm';
import Content from '../components/common/Content';
import { fontWeight, grey, spacing } from '../styles';

interface ComponentProps {
  apartment: Apartment;
}

type Props = PageProps<unknown, ComponentProps>;

const SelectedApartmentContainer = styled.div`
  padding: ${spacing['10']} ${spacing['2']};
`;

const StyledTitle = styled.h1`
  text-align: center;
  font-weight: ${fontWeight.medium};
`;

const StyledDesc = styled.p`
  text-align: center;
  padding: ${spacing['3']} 0;
`;

const ApartmentApplication = ({ pageContext }: Props): ReactElement => {
  const { apartment } = pageContext;
  const intl = useIntl();

  return (
    <Layout backgroundColor={grey['1']}>
      <SEO title={intl.formatMessage({ id: 'form.title' })} />
      <Content>
        <SelectedApartmentContainer>
          <StyledTitle>
            <FormattedMessage id="form.title" />
          </StyledTitle>
          <StyledDesc>
            <FormattedMessage id="form.desc" />
          </StyledDesc>
          <Row center="xs">
            <ApartmentCard apartment={apartment} isSmall={true} />
          </Row>
        </SelectedApartmentContainer>
        <RentApplicationForm apartment={apartment} />
      </Content>
    </Layout>
  );
};

export default ApartmentApplication;
