import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React, { ReactElement, ReactNodeArray } from 'react';
import { Row } from 'react-flexbox-grid';
import { UseFormMethods } from 'react-hook-form';
import ErrorContainer from './ErrorContainer';
import InputContainer from './InputContainer';
import Label from './Label';

import RadioSwitch from './RadioSwitch';

interface Props {
  inputRef: UseFormMethods['register'];
  hasError?: boolean;
  errorMsg?: string;
  isOptional?: boolean;
  isDisabled?: boolean;
  label: string | ReactNodeArray;
  inputName: string;
}

const RadioSwitchGroup = ({
  label,
  inputName,
  inputRef,
  hasError,
  isOptional,
  errorMsg,
  isDisabled,
}: Props): ReactElement => {
  const intl = useIntl();

  return (
    <InputContainer>
      <Label>{label}</Label>
      <Row style={{ margin: 0 }}>
        <RadioSwitch
          id={`${inputName}-yes`}
          inputName={inputName}
          inputRef={inputRef}
          label={intl.formatMessage({ id: 'common.yes' })}
          value="yes"
          side="left"
          isDisabled={isDisabled}
        />
        <RadioSwitch
          id={`${inputName}-no`}
          inputName={inputName}
          inputRef={inputRef}
          label={intl.formatMessage({ id: 'common.no' })}
          value="no"
          isOptional={isOptional}
          side="right"
          isDisabled={isDisabled}
        />
      </Row>
      {hasError && !isOptional && (
        <ErrorContainer>
          {errorMsg || <FormattedMessage id="form.common.error.requiredInfo" />}
        </ErrorContainer>
      )}
    </InputContainer>
  );
};

export default RadioSwitchGroup;
