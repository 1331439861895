import React, { ReactElement } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import styled from 'styled-components';

import {
  borderRadius,
  breakpoints,
  fontColor,
  shadows,
  spacing,
  white,
} from '../../styles';
import Image from '../common/Image';
import { Apartment } from '../../interfaces/common';
import AvailableFromIcon from '../common/icons/AvailableFromIcon';
import RentIcon from '../common/icons/RentIcon';
import FloorIcon from '../common/icons/FloorIcon';
import SizeIcon from '../common/icons/SizeIcon';
import { Link } from 'gatsby-plugin-intl';

interface Props {
  apartment: Apartment;
  isSmall?: boolean;
}

const ApartmentContainer = styled.div<Pick<Props, 'isSmall'>>`
  max-width: ${({ isSmall }) => (isSmall ? '27rem' : '30rem')};
  width: 100%;
  border-radius: ${borderRadius.xl};
  transition: 0.2s ease-in-out;
  background-color: ${white};
  position: relative;
  box-shadow: ${shadows['5']};

  &:hover {
    cursor: pointer;
    transform: translateY(-0.25rem);
  }

  margin: 0 auto;

  @media only screen and (min-width: ${breakpoints.sm}) {
    box-shadow: ${shadows['6']};
  }
`;

const InfoContainer = styled.div`
  padding: ${spacing['5']} ${spacing['4']};

  color: ${fontColor.primary};
  @media only screen and (min-width: ${breakpoints.sm}) {
    padding: ${spacing['6']} ${spacing['5']};
  }
`;

const PreviewInfoContainer = styled.div`
  padding: 0;

  @media only screen and (min-width: ${breakpoints.sm}) {
    padding: ${spacing['6']} 0 ${spacing['5']} 0;
  }
`;

const ImageContainer = styled.div`
  border-top-left-radius: ${borderRadius.xl};
  border-top-right-radius: ${borderRadius.xl};
  overflow: hidden;
`;

const AddressContainer = styled.div`
  text-align: left;
`;

const StyledSubAddress = styled.h5`
  color: ${fontColor.secondary};
  margin-bottom: 0;
`;

const StyledAddress = styled.h2`
  margin: 0;
`;

const ApartmentCard = ({ apartment, isSmall }: Props): ReactElement => (
  <ApartmentContainer isSmall={isSmall}>
    <Link to={`/${apartment.id}`}>
      <ImageContainer>
        <Image imgName={apartment.previewImageId} />
      </ImageContainer>
      <InfoContainer>
        <AddressContainer>
          <StyledAddress>{apartment.address.streetAddress}</StyledAddress>
          <StyledSubAddress>
            {apartment.address.neighbourhood} - {apartment.address.city}
          </StyledSubAddress>
        </AddressContainer>
        <PreviewInfoContainer>
          <Row center="xs" middle="xs">
            <Col md={6} xs={12}>
              <SizeIcon size={apartment.size} />
            </Col>
            <Col md={6} xs={12}>
              <FloorIcon floor={apartment.floor} />
            </Col>
          </Row>
          <RentIcon rent={apartment.rent} />
        </PreviewInfoContainer>
        <AvailableFromIcon availableFrom={apartment.availableFrom} />
      </InfoContainer>
    </Link>
  </ApartmentContainer>
);

export default ApartmentCard;
