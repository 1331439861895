import { useIntl } from 'gatsby-plugin-intl';
import React, { ReactElement } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { UseFormMethods } from 'react-hook-form';

import { RentApplicationForm, Apartment } from '../../../interfaces/common';
import Input from '../../common/forms/Input';
import RadioSwitchGroup from '../../common/forms/RadioSwitchGroup';
import TextArea from '../../common/forms/TextArea';

interface Props {
  errors: UseFormMethods<RentApplicationForm>['errors'];
  register: UseFormMethods<RentApplicationForm>['register'];
  isSubmitted: boolean;
  isLoading: boolean;
  apartment: Apartment;
  watch: UseFormMethods<RentApplicationForm>['watch'];
}

const MoreBackgroundInfoStep = ({
  errors,
  isSubmitted,
  register,
  isLoading,
  apartment,
  watch,
}: Props): ReactElement => {
  const intl = useIntl();

  const watchCredit = watch('credit');
  // const watchMoreRent = watch('moreRent');
  const watchRentFullPeriod = watch('rentFullPeriod');
  const watchPets = watch('pets');

  return (
    <Row>
      <Col xs={12} md={6}>
        <RadioSwitchGroup
          inputName="pets"
          inputRef={register}
          label={intl.formatMessage({ id: 'form.pets.label' })}
          hasError={errors.pets && isSubmitted}
          isDisabled={isLoading}
        />
        {watchPets === 'yes' && (
          <Input
            inputName="petsMoreInfo"
            inputRef={register}
            label={intl.formatMessage({ id: 'form.common.moreInfo.label' })}
            hasError={errors.petsMoreInfo && isSubmitted}
            isDisabled={isLoading}
          />
        )}

        <RadioSwitchGroup
          inputName="rentFullPeriod"
          inputRef={register}
          label={intl.formatMessage(
            { id: 'form.rentFullPeriod.label' },
            { br: <br /> },
          )}
          hasError={errors.rentFullPeriod && isSubmitted}
          isDisabled={isLoading}
        />

        {watchRentFullPeriod === 'no' && (
          <TextArea
            inputName="rentFullPeriodMoreInfo"
            inputRef={register}
            label={intl.formatMessage({
              id: 'form.rentFullPeriodMoreInfo.label',
            })}
            hasError={errors.rentFullPeriodMoreInfo && isSubmitted}
            isDisabled={isLoading}
          />
        )}

        {/* <RadioSwitchGroup
          inputName="moreRent"
          inputRef={register}
          label={intl.formatMessage(
            { id: 'form.moreRent.label' },
            { rent: apartment.rent },
          )}
          hasError={errors.moreRent && isSubmitted}
          isDisabled={isLoading}
        />

        {watchMoreRent === 'yes' && (
          <Input
            inputName="moreRentAmount"
            inputRef={register}
            type="number"
            label={intl.formatMessage({ id: 'form.moreRentAmount.label' })}
            hasError={errors.moreRentAmount && isSubmitted}
            isDisabled={isLoading}
          />
        )} */}
      </Col>
      <Col xs={12} md={6}>
        <RadioSwitchGroup
          inputName="smoke"
          inputRef={register}
          label={intl.formatMessage({ id: 'form.smoke.label' })}
          hasError={errors.smoke && isSubmitted}
          isDisabled={isLoading}
        />

        <RadioSwitchGroup
          inputName="credit"
          inputRef={register}
          label={intl.formatMessage({ id: 'form.credit.label' })}
          hasError={errors.credit && isSubmitted}
          isDisabled={isLoading}
        />
        {watchCredit === 'no' && (
          <TextArea
            isDisabled={isLoading}
            inputName="creditMoreInfo"
            inputRef={register}
            label={intl.formatMessage({ id: 'form.common.moreInfo.label' })}
            hasError={errors.creditMoreInfo && isSubmitted}
          />
        )}
      </Col>
    </Row>
  );
};

export default MoreBackgroundInfoStep;
