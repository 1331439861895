import styled from 'styled-components';
import { borderRadius, fontWeight, red, spacing } from '../../../styles';

export default styled.div`
  background-color: ${red['1']};
  border: 2px solid ${red['2']};
  padding: ${spacing['2']};
  color: ${red['7']};
  font-weight: ${fontWeight.regular};
  width: calc(100% - ${spacing['3']} * 2);
  border-radius: ${borderRadius.lg};
  margin: ${spacing['2']} 0;
`;
