import { FormattedMessage } from 'gatsby-plugin-intl';
import React, { ReactElement, ReactNodeArray } from 'react';
import { UseFormMethods } from 'react-hook-form';
import styled from 'styled-components';

import {
  borderRadius,
  breakpoints,
  fontColor,
  fontWeight,
  grey,
  purple,
  spacing,
  white,
} from '../../../styles';
import ErrorContainer from './ErrorContainer';
import InputContainer from './InputContainer';
import Label from './Label';

const StyledInput = styled.textarea<Pick<Props, 'isDisabled'>>`
  border: 2px solid ${grey['2']};
  padding: ${spacing['3']};
  border-radius: ${borderRadius.md};
  background-color: ${white};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

  min-height: ${spacing['12']};

  @media only screen and (min-width: ${breakpoints.sm}) {
    min-height: ${spacing['10']};
  }

  color: ${fontColor.primary};
  font-weight: ${fontWeight['medium']};
  width: calc(100% - ${spacing['3']} * 2);

  &:focus,
  &:active {
    border-color: ${purple['3']};
    outline: 0;
  }
`;

interface Props {
  label: string | ReactNodeArray;
  inputName: string;
  inputRef: UseFormMethods['register'];
  hasError?: boolean;
  isOptional?: boolean;
  errorMsg?: string;
  isDisabled?: boolean;
}

const TextArea = ({
  inputName,
  inputRef,
  label,
  isOptional,
  hasError,
  errorMsg,
  isDisabled,
}: Props): ReactElement => {
  return (
    <InputContainer>
      <Label htmlFor={inputName}>{label}</Label>
      <StyledInput
        disabled={isDisabled}
        isDisabled={isDisabled}
        name={inputName}
        ref={inputRef({ required: !Boolean(isOptional) })}
      />
      {hasError && !isOptional && (
        <ErrorContainer>
          {errorMsg || <FormattedMessage id="form.common.error.requiredInfo" />}
        </ErrorContainer>
      )}
    </InputContainer>
  );
};

export default TextArea;
